import React from 'react';
import PropTypes from 'prop-types';
import InViewMonitor from 'react-inview-monitor';
import {
  Container,
  Button,
  Card,
  CardImg,
  CardImgOverlay,
  CardBody,
  CardTitle,
  CardText,
} from 'reactstrap';

import { Link } from 'gatsby';

const HomePortfolio = ({
  projects,
}) => (
  <div id="portfolio" className="section section-bg">
    <Container>
      <div className="section-header mb-5">
        <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp"><h2 className="text-uppercase">Progetti</h2></InViewMonitor>
      </div>
      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        <div className="portfolio">
          {projects.map((project, index) => (
            <div key={project.slug} className={`portfolio-item ${index === 0 ? 'large' : 'medium'}`}>
              <Card className="bg-dark text-white">
                <CardImg width="100%" src={project.gallery.length > 0 ? index === 0 ? project.gallery[0].large.src : project.gallery[0].medium.src : 'https://placeimg.com/500/200/nature'} alt={project.name} />
                <CardImgOverlay className="d-flex align-items-end">
                  <CardBody>
                    <CardText tag="h6">{project.category.map(c => c.name).join(', ')}</CardText>
                    <CardTitle tag="h4">{project.name}</CardTitle>
                    <Link to={`/progetti/${project.slug}`} className="stretched-link" />
                  </CardBody>
                </CardImgOverlay>
              </Card>
            </div>
          ))}
        </div>
      </InViewMonitor>
      <div className="text-center mt-5">
        <Link to="/progetti">
          <Button color="primary" outline>
            Guarda tutti
          </Button>
        </Link>
      </div>
    </Container>
  </div>
);

HomePortfolio.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
};

HomePortfolio.defaultProps = {
  projects: [],
};

export default HomePortfolio;
