import React from 'react';
// import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import Jumbotron from '../components/Sections/Jumbotron';
import Features from '../components/Sections/Features';
import HomePortfolio from '../components/Sections/HomePortfolio';
import CustomerSlider from '../components/Sections/CustomerSlider';
import 'lazysizes';

function IndexPage({ data }) {
  // Tenere come referenza per eventuali chiamate ad api esterne
  // Funziona con lo stato ed è dinamico
  //
  // const [beers, setBeers] = useState([]);
  // const [page, setPage] = useState(1);

  // async function fetchData() {
  //   const response = await fetch(`https://api.punkapi.com/v2/beers?page=${page}&per_page=10`);
  //   const json = await response.json();
  //   setBeers(json);
  // }

  // useEffect(() => {
  //   fetchData();
  // }, [page]);

  return (
    <Layout>
      <SEO title="Geologia e bonifica di siti contaminati a Firenze" />
      <Jumbotron page={data.datoCmsHomepage} />
      <Features categories={data.allDatoCmsCategory.edges} />
      <HomePortfolio projects={data.datoCmsHomepage.projects} />
      <CustomerSlider customers={data.datoCmsHomepage.customers} />
    </Layout>
  );
}

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    datoCmsHomepage {
      id
      title
      description
      hero {
        desk: sizes(imgixParams: {fm: "webp", crop: "focalpoint", fit: "crop", h: "450", w: "1600"}, maxWidth: 1600, maxHeight: 450) {
          src
        }
        mobile: sizes(imgixParams: {fm: "webp", crop: "focalpoint", fit: "crop", h: "200", w: "400"}, maxWidth: 400, maxHeight: 200) {
          src
        }
      }
      projects {
        id
        name
        slug
        category {
          name
        }
        gallery {
        medium: sizes(imgixParams: {fm: "webp", crop: "focalpoint",  fit: "crop", h: "200", w: "500"}) {
          src
        }
        large: sizes(imgixParams: {fm: "wepb",  crop: "focalpoint", fit: "crop", h: "680", w: "800"}) {
          src
        }
      }
      }
      customers {
        name
        url
        image {
          sizes(imgixParams: {fm: "webp", auto: "compress", fit: "clip", h: "80", sat: -100, bri: 10}) {
            src
          }
        }
      }
    }
    allDatoCmsCategory(sort: {order: ASC, fields: name}) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`;
