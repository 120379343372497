import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import {
  Container,
} from 'reactstrap';

import Logo from '../../assets/img/default_customer.png';

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  arrows: false,
};

const CustomerSlider = ({ customers }) => (
  <div id="customers" className="section d-none d-sm-block">
    <Container>
      <div className="section-header mb-5">
        <h2 className="text-uppercase">Clienti</h2>
      </div>
      {customers.length > 4 ? (
        <Slider {...settings}>
          {customers.map(customer => (
            <div key={customer.name}>
              {customer.url
                ? <a rel="noopener noreferrer" target="_blank" href={customer.url}><img src={customer.image ? customer.image.sizes.src : Logo} className="align-self-center lazyload" alt={customer.name} /></a>
                : <img src={customer.image ? customer.image.sizes.src : Logo} className="align-self-center lazyload" alt={customer.name} />
            }
            </div>
          ))}
        </Slider>
      ) : null
  }
    </Container>
  </div>
);

CustomerSlider.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.shape({})),
};

CustomerSlider.defaultProps = {
  customers: [],
};

export default CustomerSlider;
