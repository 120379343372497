import React from 'react';
import PropTypes from 'prop-types';
import {
  Jumbotron,
  Container,
  Row,
  Col,
} from 'reactstrap';
import Markdown from 'react-markdown';
import { isMobile } from "react-device-detect";


const FixedJumbotron = ({
  page,
}) => {
let jumboBg = page.hero.desk.src
if (isMobile) {
  jumboBg = page.hero.mobile.src
}
  return (
  <Jumbotron>
    <div className="jumbotron-bg-holder" style={{ backgroundImage: `url(${jumboBg})` }}>
      <Container>
        <Row>
          <Col sm="8">
            <div className="jumbotron-text-holder text-primary hidden-xs-down">
              <h1>{page.title}</h1>
              <div className="mt-3 text-primary"><Markdown source={page.description} /></div>
            </div>
          </Col>
          <Col sm="4" className="hidden-xs-down">
            <div className="device pull-right" />
          </Col>
        </Row>
      </Container>
    </div>
  </Jumbotron>
);
};

FixedJumbotron.propTypes = {
  page: PropTypes.shape({
    hero: PropTypes.shape({
      sizes: PropTypes.shape({
        src: PropTypes.string,
      }),
    }),
    title: PropTypes.string,
    description: PropTypes.string,

  }).isRequired,
};

export default FixedJumbotron;
