import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import InViewMonitor from 'react-inview-monitor';
import {
  Container,
  Card,
  CardImg,
  CardTitle,
  CardDeck,
  CardBody,
} from 'reactstrap';

const Features = ({ categories }) => {
  let rowContent = [];
  const content = categories.reduce((acc, category, idx) => {
    rowContent.push(
      <Card className="p-5" key={category.node.slug}>
        {/* eslint-disable-next-line */}
           <CardImg top width="100%" className="align-self-center" src={require(`../../assets/img/svg/${category.node.slug}.svg`)} alt={`${category.node.name}`} />
        <CardBody>
          <CardTitle tag="h3" className="h4">{category.node.name}</CardTitle>
        </CardBody>
        <Link to={`/servizi#servizio-${category.node.slug}`} className="stretched-link" />
      </Card>,
    );
    if ((idx + 1) % 3 === 0) {
      acc.push(<InViewMonitor key={category.node.slug} classNameNotInView="animate-box" classNameInView="animated fadeInUp"><CardDeck className={`${idx === 2 ? 'mb-md-5' : 'mb-0'}`}>{rowContent}</CardDeck></InViewMonitor>);
      rowContent = [];
    }
    return acc;
  }, []);
  return (
    <div id="services" className="section">
      <Container>
        <div className="section-header mb-5">
          <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp"><h2 className="text-uppercase">Servizi</h2></InViewMonitor>
        </div>
        {categories.length > 0 && content}
      </Container>
    </div>
  );
};

Features.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
};

export default Features;
